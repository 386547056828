<template>
  <!-- begin:: Footer -->
  <div class="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
    <div
      class="d-flex align-items-center justify-content-between"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="text-dark">
        <span class="text-muted font-weight-bold mr-2">
          {{ currentYear }} &nbsp;&copy;&nbsp;
        </span>
        <a
          :href="copyrightUrl"
          target="_blank"
          class="text-dark-75 text-hover-primary"
        >
          {{ copyright }}
        </a>
      </div>
      <div class="nav nav-dark">
        <a
          v-for="(item, index) in footerMenuItems"
          :key="index"
          :href="item.url"
          :target="item.target"
          class="nav-link pr-3 pl-0"
        >
          {{ $t(item.label) }}
        </a>
      </div>
    </div>
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTFooter",
  computed: {
    ...mapGetters([
      "layoutConfig",
      "copyright",
      "copyrightUrl",
      "footerMenuItems"
    ]),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    },
    currentYear() {
      var date = new Date();
      return date.getFullYear();
    }
  }
};
</script>
