export default [
  {
    path: "/",
    redirect: "/cms/dashboard"
  },
  {
    path: "/cms",
    redirect: "/cms/dashboard",
    component: () => import(/* webpackChunkName: "Layout" */ "@/layout/Layout"),
    children: [
      {
        path: "/cms/dashboard",
        name: "cmsDashboard",
        meta: {
          authorize: true
        },
        component: () =>
          import(/* webpackChunkName: "CDB" */ "@/cms/views/Dashboard.vue")
      },
      {
        path: "/cms/categories",
        name: "cmsCategoryList",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: true,
            items: [
              {
                title: "cms.category.list.title",
                route: { name: "cmsCategoryList" }
              }
            ]
          }
        },
        props: {
          default: {
            formRoute: "cmsCategoryForm",
            detailRoute: "cmsCategoryDetail",
            rowClickAction: "edit"
          }
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "CCL" */ "@/cms/views/category/CategoryList.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "CCLA" */ "@/cms/views/category/CategoryListActions.vue"
            )
        }
      },
      {
        path: "/cms/category/:id?",
        name: "cmsCategoryForm",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: false,
            items: [
              {
                title: "cms.category.form.title",
                route: { name: "cmsCategoryForm" }
              }
            ]
          }
        },
        props: {
          default: route => ({
            formRoute: "cmsCategoryForm",
            detailRoute: "cmsCategoryDetail",
            listRoute: "cmsCategoryList",
            id: route.params["id"]
          })
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "CCF" */ "@/cms/views/category/CategoryForm.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "CCFA" */ "@/cms/views/category/CategoryFormActions.vue"
            )
        }
      },
      {
        path: "/cms/pages",
        name: "cmsPageList",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: true,
            items: [
              {
                title: "cms.page.list.title",
                route: { name: "cmsPageList" }
              }
            ]
          }
        },
        props: {
          default: {
            formRoute: "cmsPageForm",
            detailRoute: "cmsPageDetail",
            rowClickAction: "edit"
          }
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "CPL" */ "@/cms/views/page/PageList.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "CPLA" */ "@/cms/views/page/PageListActions.vue"
            )
        }
      },
      {
        path: "/cms/page/:id?",
        name: "cmsPageForm",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: false,
            items: [
              {
                title: "cms.page.form.title",
                route: { name: "cmsPageForm" }
              }
            ]
          }
        },
        props: {
          default: route => ({
            formRoute: "cmsPageForm",
            detailRoute: "cmsPageDetail",
            listRoute: "cmsPageList",
            id: route.params["id"]
          })
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "CPF" */ "@/cms/views/page/PageForm.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "CPFA" */ "@/cms/views/page/PageFormActions.vue"
            )
        }
      },
      {
        path: "/cms/sites",
        name: "cmsSiteList",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: true,
            items: [
              {
                title: "cms.site.list.title",
                route: { name: "cmsSiteList" }
              }
            ]
          }
        },
        props: {
          default: {
            formRoute: "cmsSiteForm",
            detailRoute: "cmsSiteDetail",
            rowClickAction: "edit"
          }
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "SL" */ "@/cms/views/site/SiteList.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "SLA" */ "@/cms/views/site/SiteListActions.vue"
            )
        }
      },
      {
        path: "/cms/site/:id?",
        name: "cmsSiteForm",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: false,
            items: [
              {
                title: "cms.site.form.title",
                route: { name: "cmsSiteForm" }
              }
            ]
          }
        },
        props: {
          default: route => ({
            formRoute: "cmsSiteForm",
            detailRoute: "cmsSiteDetail",
            listRoute: "cmsSiteList",
            id: route.params["id"]
          })
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "SF" */ "@/cms/views/site/SiteForm.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "SFA" */ "@/cms/views/site/SiteFormActions.vue"
            )
        }
      },
      {
        path: "/cms/themes",
        name: "cmsThemeList",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: true,
            items: [
              {
                title: "cms.theme.list.title",
                route: { name: "cmsThemeList" }
              }
            ]
          }
        },
        props: {
          default: {
            formRoute: "cmsThemeForm",
            detailRoute: "cmsThemeDetail",
            rowClickAction: "edit"
          }
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "SL" */ "@/cms/views/theme/ThemeInfoList.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "SLA" */ "@/cms/views/theme/ThemeInfoListActions.vue"
            )
        }
      },
      {
        path: "/cms/theme/:id?",
        name: "cmsThemeForm",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: false,
            items: [
              {
                title: "cms.theme.form.title",
                route: { name: "cmsThemeForm" }
              }
            ]
          }
        },
        props: {
          default: route => ({
            formRoute: "cmsThemeForm",
            detailRoute: "cmsThemeDetail",
            listRoute: "cmsThemeList",
            id: route.params["id"]
          })
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "SF" */ "@/cms/views/theme/ThemeForm.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "SFA" */ "@/cms/views/theme/ThemeFormActions.vue"
            )
        }
      },
      {
        path: "/cms/fileManager",
        name: "cmsFileManager",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: true,
            items: [
              {
                title: "cms.site.list.title",
                route: { name: "cmsFileManager" }
              }
            ]
          }
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "SL" */ "@/cms/views/fileManager/FileManagerView.vue"
            )
        }
      },
      {
        path: "/cms/newses",
        name: "cmsNewsList",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: true,
            items: [
              {
                title: "cms.news.list.title",
                route: { name: "cmsNewsList" }
              }
            ]
          }
        },
        props: {
          default: {
            formRoute: "cmsNewsForm",
            detailRoute: "cmsNewsDetail",
            rowClickAction: "edit",
            postType: "News"
          }
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "PostList" */ "@/cms/views/post/PostList.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "PostListActions" */ "@/cms/views/post/PostListActions.vue"
            )
        }
      },
      {
        path: "/cms/headlines",
        name: "cmsNewsHeadlineList",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: true,
            items: [
              {
                title: "cms.news.list.title",
                route: { name: "cmsNewsHeadlineList" }
              }
            ]
          }
        },
        props: {
          default: {
            formRoute: "cmsNewsForm",
            detailRoute: "cmsNewsDetail",
            rowClickAction: "edit",
            postType: "News"
          }
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "PostHeadlineList" */ "@/cms/views/post/PostHeadlineList.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "PostListActions" */ "@/cms/views/post/PostListActions.vue"
            )
        }
      },
      {
        path: "/cms/news/:id?",
        name: "cmsNewsForm",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: false,
            items: [
              {
                title: "cms.news.form.title",
                route: { name: "cmsNewsForm" }
              }
            ]
          }
        },
        props: {
          default: route => ({
            formRoute: "cmsNewsForm",
            detailRoute: "cmsNewsDetail",
            listRoute: "cmsNewsList",
            id: route.params["id"]
          })
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "PostForm" */ "@/cms/views/post/PostForm.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "PostFormActions" */ "@/cms/views/post/PostFormActions.vue"
            )
        }
      },
      {
        path: "/cms/publications",
        name: "cmsPublicationList",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: true,
            items: [
              {
                title: "cms.publication.list.title",
                route: { name: "cmsPublicationList" }
              }
            ]
          }
        },
        props: {
          default: {
            formRoute: "cmsPublicationForm",
            detailRoute: "cmsPublicationDetail",
            rowClickAction: "edit"
          }
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "PL" */ "@/cms/views/publication/PublicationList.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "PLA" */ "@/cms/views/publication/PublicationListActions.vue"
            )
        }
      },
      {
        path: "/cms/publication/:id?",
        name: "cmsPublicationForm",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: false,
            items: [
              {
                title: "cms.publication.form.title",
                route: { name: "cmsPublicationForm" }
              }
            ]
          }
        },
        props: {
          default: route => ({
            formRoute: "cmsPublicationForm",
            detailRoute: "cmsPublicationDetail",
            listRoute: "cmsPublicationList",
            id: route.params["id"]
          })
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "PF" */ "@/cms/views/publication/PublicationForm.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "PFA" */ "@/cms/views/publication/PublicationFormActions.vue"
            )
        }
      },
      {
        path: "/cms/tvVideos",
        name: "cmsTvVideoList",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: true,
            items: [
              {
                title: "cms.tvVideo.list.title",
                route: { name: "cmsTvVideoList" }
              }
            ]
          }
        },
        props: {
          default: {
            formRoute: "cmsTvVideoForm",
            detailRoute: "cmsTvVideoDetail",
            rowClickAction: "edit"
          }
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "TvVideoList" */ "@/cms/views/tvVideo/TvVideoList.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "TvVideoListActions" */ "@/cms/views/tvVideo/TvVideoListActions.vue"
            )
        }
      },
      {
        path: "/cms/tvVideo/:id?",
        name: "cmsTvVideoForm",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: false,
            items: [
              {
                title: "cms.tvVideo.form.title",
                route: { name: "cmsTvVideoForm" }
              }
            ]
          }
        },
        props: {
          default: route => ({
            formRoute: "cmsTvVideoForm",
            detailRoute: "cmsTvVideoDetail",
            listRoute: "cmsTvVideoList",
            id: route.params["id"]
          })
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "TvVideoForm" */ "@/cms/views/tvVideo/TvVideoForm.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "TvVideoFormActions" */ "@/cms/views/tvVideo/TvVideoFormActions.vue"
            )
        }
      },
      {
        path: "/cms/liveStreams",
        name: "cmsLiveStreamList",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: true,
            items: [
              {
                title: "cms.liveStream.list.title",
                route: { name: "cmsLiveStreamList" }
              }
            ]
          }
        },
        props: {
          default: {
            formRoute: "cmsLiveStreamForm",
            detailRoute: "cmsLiveStreamDetail",
            rowClickAction: "edit"
          }
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "LiveStreamList" */ "@/cms/views/liveStream/LiveStreamList.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "LiveStreamListActions" */ "@/cms/views/liveStream/LiveStreamListActions.vue"
            )
        }
      },
      {
        path: "/cms/liveStream/:id?",
        name: "cmsLiveStreamForm",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: false,
            items: [
              {
                title: "cms.liveStream.form.title",
                route: { name: "cmsLiveStreamForm" }
              }
            ]
          }
        },
        props: {
          default: route => ({
            formRoute: "cmsLiveStreamForm",
            detailRoute: "cmsLiveStreamDetail",
            listRoute: "cmsLiveStreamList",
            id: route.params["id"]
          })
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "LiveStreamForm" */ "@/cms/views/liveStream/LiveStreamForm.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "LiveStreamFormActions" */ "@/cms/views/liveStream/LiveStreamFormActions.vue"
            )
        }
      },
      {
        path: "/cms/forms",
        name: "cmsFormList",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: true,
            items: [
              {
                title: "cms.form.list.title",
                route: { name: "cmsFormList" }
              }
            ]
          }
        },
        props: {
          default: {
            formRoute: "cmsFormForm",
            detailRoute: "cmsFormDetail",
            rowClickAction: "edit"
          }
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "FormList" */ "@/cms/views/form/FormList.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "FormListActions" */ "@/cms/views/form/FormListActions.vue"
            )
        }
      },
      {
        path: "/cms/form/:id?",
        name: "cmsFormForm",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: false,
            items: [
              {
                title: "cms.form.form.title",
                route: { name: "cmsFormForm" }
              }
            ]
          }
        },
        props: {
          default: route => ({
            formRoute: "cmsFormForm",
            detailRoute: "cmsFormDetail",
            listRoute: "cmsFormList",
            id: route.params["id"]
          })
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "FormForm" */ "@/cms/views/form/FormForm.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "FormFormActions" */ "@/cms/views/form/FormFormActions.vue"
            )
        }
      },
      {
        path: "/cms/calendars",
        name: "cmsCalendarList",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: true,
            items: [
              {
                title: "cms.calendar.list.title",
                route: { name: "cmsCalendarList" }
              }
            ]
          }
        },
        props: {
          default: {
            formRoute: "cmsCalendarForm",
            detailRoute: "cmsCalendarDetail",
            rowClickAction: "detail"
          }
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "CalendarList" */ "@/cms/views/calendar/CalendarList.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "CalendarListActions" */ "@/cms/views/calendar/CalendarListActions.vue"
            )
        }
      },
      {
        path: "/cms/calendar/:id?",
        name: "cmsCalendarForm",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: false,
            items: [
              {
                title: "cms.calendar.form.title",
                route: { name: "cmsCalendarForm" }
              }
            ]
          }
        },
        props: {
          default: route => ({
            formRoute: "cmsCalendarForm",
            detailRoute: "cmsCalendarDetail",
            listRoute: "cmsCalendarList",
            id: route.params["id"]
          })
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "CalendarForm" */ "@/cms/views/calendar/CalendarForm.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "CalendarFormActions" */ "@/cms/views/calendar/CalendarFormActions.vue"
            )
        }
      },
      {
        path: "/cms/calendar/:id?/items",
        name: "cmsCalendarDetail",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: false,
            items: [
              {
                title: "cms.calendar.detail.title",
                route: { name: "cmsCalendarDetail" }
              }
            ]
          }
        },
        props: {
          default: route => ({
            formRoute: "cmsCalendarDetail",
            detailRoute: "cmsCalendarDetail",
            listRoute: "cmsCalendarList",
            id: route.params["id"]
          })
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "CalendarDetail" */ "@/cms/views/calendar/CalendarDetail.vue"
            )
        }
      },
      {
        path: "/cms/newsletter/:id?",
        name: "cmsNewsletterForm",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: false,
            items: [
              {
                title: "cms.newsletter.form.title",
                route: { name: "cmsNewsletterForm" }
              }
            ]
          }
        },
        props: {
          default: route => ({
            formRoute: "cmsNewsletterForm",
            detailRoute: "cmsNewsletterDetail",
            listRoute: "cmsNewsletterList",
            id: route.params["id"]
          })
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "NewsletterForm" */ "@/cms/views/newsletter/NewsletterForm.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "NewsletterFormActions" */ "@/cms/views/newsletter/NewsletterFormActions.vue"
            )
        }
      },
      {
        path: "/cms/newsletters",
        name: "cmsNewsletterList",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: true,
            items: [
              {
                title: "cms.newsletter.list.title",
                route: { name: "cmsNewsletterList" }
              }
            ]
          }
        },
        props: {
          default: {
            formRoute: "cmsNewsletterForm",
            detailRoute: "cmsNewsletterDetail",
            rowClickAction: "edit"
          }
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "NewsletterList" */ "@/cms/views/newsletter/NewsletterList.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "NewsletterListActions" */ "@/cms/views/newsletter/NewsletterListActions.vue"
            )
        }
      }
    ]
  },
  {
    path: "/cms/selector",
    component: () =>
      import(/* webpackChunkName: "Layout" */ "@/layout/LayoutWithoutAside"),
    children: [
      {
        path: "/cms/selector/site",
        name: "cmsSelectSite",
        meta: {
          authorize: true
        },
        component: () =>
          import(
            /* webpackChunkName: "SelectSite" */ "@/cms/views/site/SelectSite"
          )
      }
    ]
  }
];
