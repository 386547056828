<template>
  <router-link
    :to="{ name: info.routeName, params: { ...info.routeParams } }"
    v-slot="{ href, navigate, isActive, isExactActive }"
  >
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item"
      :class="[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ]"
    >
      <a :href="href" class="menu-link" @click="navigate">
        <i v-if="info.icon !== null" :class="info.icon" class="menu-icon"></i>
        <span class="menu-text">{{ $t(info.label) }}</span>
      </a>
    </li>
  </router-link>
</template>

<script>
export default {
  name: "MenuItem",
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  methods: {}
};
</script>

<style scoped></style>
