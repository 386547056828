export default {
  state: {
    currentSite: null
  },
  mutations: {
    setCurrentSite(state, site) {
      state.currentSite = site;
    }
  },
  actions: {
    setCurrentSite(context, site) {
      context.commit("setCurrentSite", site);
    }
  },
  getters: {
    currentSite(state) {
      return state.currentSite;
    },
    isCurrentSiteSelected(state) {
      return state.currentSite != null;
    },
    themePages(state) {
      if (state.currentSite == null) {
        return [];
      }
      return state.currentSite.theme.pages;
    },
    themeImageFormats(state) {
      if (state.currentSite == null) {
        return [];
      }
      return state.currentSite.theme.imageFormats;
    },
    themeHeadLineZones(state) {
      if (state.currentSite == null) {
        return [];
      }
      if (state.currentSite.theme == null) {
        return [];
      }
      if (state.currentSite.theme.zones == null) {
        return [];
      }
      return state.currentSite.theme.zones.filter(x => x.isHeadLine);
    },
    subSites(state) {
      if (state.currentSite == null) {
        return [];
      }
      if (state.currentSite.subSites == null) {
        return [];
      }
      return state.currentSite.subSites;
    }
  }
};
