import objectPath from "object-path";
export default {
  state: {
    config: null
  },
  mutations: {
    setConfig(state, config) {
      state.config = config;
    }
  },
  actions: {
    setConfig(context, config) {
      context.commit("setConfig", config);
    }
  },
  getters: {
    loginAfterRoute(state) {
      return state.config.auth.login.afterRoute;
    },
    layoutConfig: state => (path, defaultValue) => {
      return objectPath.get(state.config.layout, path, defaultValue);
    },
    userPanelMenuItems(state) {
      return state.config.layout.userMenu.menuItems;
    },
    userPanelExtraComponent(state) {
      return state.config.layout.userMenu.extraComponent;
    },
    hasUserPanelExtraComponent(state) {
      return (
        state.config.layout.userMenu.extraComponent !== "" &&
        state.config.layout.userMenu.extraComponent !== null &&
        state.config.layout.userMenu.extraComponent !== undefined
      );
    },
    appLoginConfig(state) {
      return state.config.auth.login;
    },
    appValidateConfig(state) {
      return state.config.auth.validate;
    },
    isMultiLanguageEnabled(state) {
      return state.config.enableMultiLanguage;
    },
    appLanguages(state) {
      if (!state.config.enableMultiLanguage) {
        return [];
      }
      return state.config.languages;
    },
    isRightPanelMenuEnabled(state) {
      return state.config.enableRightPanelMenu;
    },
    rightPanelMenuIcon(state) {
      return state.config.rightPanelMenu.icon;
    },
    rightPanelMenuTabs(state) {
      return state.config.rightPanelMenu.tabs;
    },
    isCartEnabled(state) {
      return state.config.enableCart;
    },
    isActionMenuEnabled(state) {
      return state.config.enableActionMenu;
    },
    actionMenuItems(state) {
      return state.config.actionMenu.items;
    },
    actionMenuTitle(state) {
      return state.config.actionMenu.title;
    },
    actionMenuBadge(state) {
      return state.config.actionMenu.badge;
    },
    isNotificationsEnabled(state) {
      return state.config.enableNotifications;
    },
    isSearchMenuEnabled(state) {
      return state.config.enableSearchMenu;
    },
    asideMenuItems(state) {
      return state.config.aside.menuItems;
    },
    isCompanyLogoEnabled(state) {
      return state.config.enableCompanyLogo;
    },
    companyLogo(state) {
      return state.config.companyLogo;
    },
    copyright(state) {
      return state.config.footer.copyright;
    },
    copyrightUrl(state) {
      return state.config.footer.copyrightUrl;
    },
    footerMenuItems(state) {
      return state.config.footer.menuItems;
    },
    appProfileConfig(state) {
      return state.config.profile;
    },
    appErrorConfig(state) {
      return state.config.auth.login.error;
    },
    currentUser(state) {
      return state.config.avatar.confirm;
    },
    appRegisterConfig(state) {
      return state.config.register.registerPage;
    },
    appRegisterCompleteConfig(state) {
      return state.config.register.registerCompletePage;
    },
    appRegisterVerificationPage(state) {
      return state.config.register.registerVerificationPage;
    },
    appRegisterAsidePage(state) {
      return state.config.register.registerAsidePage;
    }
  }
};
