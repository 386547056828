import { ApiClient, ApiError } from "@appkit-vue/data";
import { Observable } from "rxjs";

export class SignInManager extends ApiClient {
  /**
   *
   * @param apiContext
   */
  constructor(context) {
    super(context);
  }

  login(model) {
    return new Observable(subscriber => {
      console.log(this.getContext().config);
      this.getContext()
        .post("identity/authentication/signin", model)
        .then(response => {
          if (response.status === 200) {
            if (response.data.status === 200) {
              if (response.data.data.type === 0) {
                this.context.tokenStorage.saveToken(
                  response.data.data.token.accessToken
                );
              }
            }
          }
          this.handleResponse(subscriber, response);
        })
        .catch(error => {
          this.handleResponse(subscriber, error);
        });
    });
  }

  resendValidationCode(token) {
    return new Observable(subscriber => {
      this.getContext()
        .post("identity/authentication/" + token + "/resend")
        .then(response => {
          this.handleResponse(subscriber, response);
        })
        .catch(error => {
          this.handleResponse(subscriber, error);
        });
    });
  }

  validate(model) {
    return new Observable(subscriber => {
      this.getContext()
        .post("identity/authentication/validate", model)
        .then(response => {
          if (response.status === 200) {
            if (response.data.status === 200) {
              if (response.data.data.type === 0) {
                this.context.tokenStorage.saveToken(
                  response.data.data.token.accessToken
                );
              } else {
                subscriber.error(
                  new ApiError(
                    0,
                    null,
                    500,
                    "Not Supported",
                    "NOTSUPPORTED",
                    "Desteklenmeyen cevap. Lütfen sistem yöneticisi ile görüşünüz.",
                    new Date()
                  )
                );
              }
            }
          }

          this.handleResponse(subscriber, response);
        })
        .catch(error => {
          this.handleResponse(subscriber, error);
        });
    });
  }

  details() {
    return new Observable(subscriber => {
      this.getContext()
        .get("identity/Authentication")
        .then(response => {
          this.handleResponse(subscriber, response);
        })
        .catch(error => {
          this.handleResponse(subscriber, error);
        });
    });
  }

  hasToken() {
    return this.context.tokenStorage.hasToken();
  }
}
