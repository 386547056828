export default {
  beforeRoute(to, from, next) {
    if (to.matched.some(record => record.meta.authorize)) {
      window.webApp.store.dispatch("checkAuthentication").then(
        () => {
          next();
        },
        error => {
          if (error.code === "NOAUTHORIZE" || error.code === "NOUSER") {
            next({
              name: "login"
            });
          } else {
            next({
              name: "error"
            });
          }
        }
      );
    } else {
      next();
    }
  },
  afterRoute() {}
};
