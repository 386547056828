var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex flex-column flex-center py-10 bgi-size-cover bgi-no-repeat rounded-top",style:({ backgroundImage: ("url(" + _vm.backgroundImage + ")") })},[(
        _vm.actionMenuTitle !== '' &&
          _vm.actionMenuTitle !== null &&
          _vm.actionMenuTitle !== undefined
      )?_c('h4',{staticClass:"text-white font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t(_vm.actionMenuTitle))+" ")]):_vm._e(),(
        _vm.actionMenuBadge !== '' &&
          _vm.actionMenuBadge !== null &&
          _vm.actionMenuBadge !== undefined
      )?_c('span',{staticClass:"btn btn-success btn-sm font-weight-bold font-size-sm mt-2"},[_vm._v(" "+_vm._s(_vm.$t(_vm.actionMenuBadge))+" ")]):_vm._e()]),_c('div',{staticClass:"row row-paddingless"},[_vm._l((_vm.actionMenuItems),function(item,i){return [_c('div',{key:i,staticClass:"col-6"},[_c('router-link',{staticClass:"d-block py-10 px-5 text-center bg-hover-light border-right border-bottom",attrs:{"to":{ name: item.route }}},[_c('span',{staticClass:"svg-icon svg-icon-3x svg-icon-success"},[_c('inline-svg',{attrs:{"src":item.svg}})],1),_c('span',{staticClass:"d-block text-dark-75 font-weight-bold font-size-h6 mt-2 mb-1"},[_vm._v(" "+_vm._s(_vm.$t(item.label))+" ")]),_c('span',{staticClass:"d-block text-dark-50 font-size-lg"},[_vm._v(" "+_vm._s(_vm.$t(item.description))+" ")])])],1)]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }