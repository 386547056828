export default {
  state: {
    loading: false,
    results: []
  },
  mutations: {
    setLoading(state, status) {
      state.loading = status;
    }
  },
  actions: {
    globalSearch(context, query) {
      context.commit("setLoading", true);
      console.log(query);
    }
  },
  getters: {
    isSearchLoading(state) {
      return state.loading;
    },
    searchResults(state) {
      return state.results;
    }
  }
};
