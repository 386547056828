import Vue from "vue";
import Vuex from "vuex";
import {
  VueI18n,
  VueRouter,
  WebAppRoot,
  WebAppRoutes,
  WebAppStoreModules
} from "./index";
import WebApp from "./index";
import { LangTR, LangEN } from "@appkit-vue/ui";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";
/*
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/metronic";*/
import "@mdi/font/css/materialdesignicons.css";
import config from "@/app.settings.json";
import api from "./api.js";

import cmsRoutes from "@/cms/router/cms.routes";
import chpRoutes from "@/chp/master/router/chp.routes";

const routes = [...WebAppRoutes, ...cmsRoutes, ...chpRoutes];

Vue.prototype.$apiContext = api;
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
Vue.use(Vuex);
import cmsStore from "@/cms/store/cms.module";
const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    ...WebAppStoreModules,
    cmsStore
  }
});

import identityApi from "./test_identity_api";
Vue.use(WebApp, {
  config: config,
  router: router,
  store: store,
  identityApi: identityApi
});

Vue.config.productionTip = false;

Vue.prototype.$config = Object.freeze({
  imageUploadSize: 26214400, // 25mb
  fileUploadSize: 104857600, // 100mb
  supportedImages: [".jpg", ".jpeg", ".png", ".gif"],
  supportedVideos: [".mp4", ".avi", ".mpeg", ".3gp"]
});

import CMSLangTR from "@/cms/i18n/tr.json";
import CHPLangTR from "@/chp/master/i18n/tr.json";

const i18n = new VueI18n({
  locale: config.defaultLanguage,
  messages: {
    tr: {
      //...defaultLanguage,
      ...LangTR,
      ...CMSLangTR,
      ...CHPLangTR
    },
    en: {
      ...LangEN
    }
  }
});

new Vue({
  i18n,
  router: router,
  store,
  render: h => h(WebAppRoot)
}).$mount("#app");
