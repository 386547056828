export default [
  {
    path: "/me",
    redirect: "/me/profile",
    component: () =>
      import(/* webpackChunkName: "Layout" */ "@/layout/Layout.vue"),
    children: [
      {
        path: "/me/profile",
        redirect: "/me/profile/information",
        component: () =>
          import(
            /* webpackChunkName: "PA" */ "@/auth/profile/ProfileAccount.vue"
          ),
        children: [
          {
            path: "/me/profile/information",
            meta: {
              authorize: true
            },
            name: "myProfileInformation",
            component: () =>
              import(
                /* webpackChunkName: "PI" */ "@/auth/profile/PersonalInformation.vue"
              )
          },
          {
            path: "/me/profile/settings",
            meta: {
              authorize: true
            },
            name: "myPersonalSettings",
            component: () =>
              import(
                /* webpackChunkName: "PS" */ "@/auth/profile/PersonalSettings.vue"
              )
          },
          {
            path: "/me/profile/changePassword",
            meta: {
              authorize: true
            },
            name: "myChangePassword",
            component: () =>
              import(
                /* webpackChunkName: "CP" */ "@/auth/profile/ChangePassword.vue"
              )
          }
        ]
      }
    ]
  },
  {
    path: "/error",
    name: "error",
    component: () => import(/* webpackChunkName: "Error" */ "@/error/Error.vue")
  }
];
