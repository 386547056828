<template>
  <div>
    <!--begin::Header -->
    <div
      class="d-flex flex-column flex-center py-10 bgi-size-cover bgi-no-repeat rounded-top"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <h4
        v-if="
          actionMenuTitle !== '' &&
            actionMenuTitle !== null &&
            actionMenuTitle !== undefined
        "
        class="text-white font-weight-bold"
      >
        {{ $t(actionMenuTitle) }}
      </h4>
      <span
        v-if="
          actionMenuBadge !== '' &&
            actionMenuBadge !== null &&
            actionMenuBadge !== undefined
        "
        class="btn btn-success btn-sm font-weight-bold font-size-sm mt-2"
      >
        {{ $t(actionMenuBadge) }}
      </span>
    </div>
    <!--end::Header -->

    <!--begin::Nav -->
    <div class="row row-paddingless">
      <!--begin:Item-->
      <template v-for="(item, i) in actionMenuItems">
        <div class="col-6" v-bind:key="i">
          <router-link
            :to="{ name: item.route }"
            class="d-block py-10 px-5 text-center bg-hover-light border-right border-bottom"
          >
            <span class="svg-icon svg-icon-3x svg-icon-success">
              <!--begin::Svg Icon-->
              <inline-svg :src="item.svg" />
              <!--end::Svg Icon-->
            </span>
            <span
              class="d-block text-dark-75 font-weight-bold font-size-h6 mt-2 mb-1"
            >
              {{ $t(item.label) }}
            </span>
            <span class="d-block text-dark-50 font-size-lg">
              {{ $t(item.description) }}
            </span>
          </router-link>
        </div>
      </template>
      <!--end:Item-->
    </div>
    <!--end::Nav -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "KTDropdownQuickAction",
  data() {
    return {
      list: [
        {
          title: "Accounting",
          desc: "eCommerce",
          svg: process.env.BASE_URL + "assets/svg/icons/Shopping/Euro.svg"
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["actionMenuItems", "actionMenuTitle", "actionMenuBadge"]),
    backgroundImage() {
      return process.env.BASE_URL + "assets/misc/bg-1.jpg";
    }
  }
};
</script>
