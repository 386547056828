import { ApiContext } from "@appkit-vue/data";

export default new ApiContext({
  baseURL: process.env.VUE_APP_IDENTITY_BASEURL,
  origin: process.env.VUE_APP_API_ORIGIN,
  tokenKey: process.env.VUE_APP_TOKEN_KEY,
  onRequest() {
    const item = window.sessionStorage.getItem("currentSubscription");
    if (item !== undefined && item != null && item != "") {
      var response = [];
      response["X-Subscription"] = item;
      return response;
    }
    return [];
  }
});
