<template>
  <form>
    <!--begin::Header-->
    <div
      class="d-flex align-items-center py-10 px-8 bgi-size-cover bgi-no-repeat rounded-top"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <span class="btn btn-md btn-icon bg-white-o-15 mr-4">
        <i class="flaticon2-shopping-cart-1 text-success" />
      </span>
      <h4 class="text-white m-0 flex-grow-1 mr-3">{{ $t("cartTitle") }}</h4>
      <button type="button" class="btn btn-success btn-sm">
        {{ $t("cartCount", { count: cartItemCount }) }}
      </button>
    </div>
    <!--end::Header-->

    <div class="scroll scroll-push">
      <perfect-scrollbar
        class="scroll"
        style="max-height: 30vh; position: relative"
      >
        <!--begin::Item-->
        <template v-for="(item, i) in cartItems">
          <div
            class="d-flex align-items-center justify-content-between p-8"
            v-bind:key="i"
          >
            <div class="d-flex flex-column mr-2">
              <a
                class="font-weight-bold text-dark-75 font-size-lg text-hover-primary"
              >
                {{ item.title }}
              </a>
              <span class="text-muted">
                {{ item.desc }}
              </span>
              <div class="d-flex align-items-center mt-2">
                <span class="font-weight-bold mr-1 text-dark-75 font-size-3">
                  {{ item.price }}
                </span>
                <span class="text-muted mr-1">x</span>
                <span class="font-weight-bold mr-2 text-dark-75 font-size-3">
                  {{ item.quantity }}
                </span>
              </div>
            </div>
            <a
              v-if="item.img != '' && item.img != undefined && item.img != null"
              class="symbol symbol-70 flex-shrink-0"
            >
              <img :src="item.img" alt="" />
            </a>
          </div>
        </template>
        <!--end::Item-->
        <div class="separator separator-solid"></div>
      </perfect-scrollbar>
    </div>

    <!--begin::Summary-->
    <div class="p-8">
      <div class="d-flex align-items-center justify-content-between mb-4">
        <span class="font-weight-bold text-muted font-size-sm mr-2">{{
          $t("itemTotal")
        }}</span>
        <span class="font-weight-bolder text-dark-50 text-right">{{
          cartItemTotal
        }}</span>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-4">
        <span class="font-weight-bold text-muted font-size-sm mr-2">{{
          $t("vatTotal")
        }}</span>
        <span class="font-weight-bolder text-dark-50 text-right">{{
          cartItemVatTotal
        }}</span>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-7">
        <span class="font-weight-bold text-muted font-size-sm mr-2">{{
          $t("grandTotal")
        }}</span>
        <span class="font-weight-bolder text-primary text-right">{{
          cartGrandTotal
        }}</span>
      </div>
      <div class="text-right">
        <button type="button" class="btn btn-primary text-weight-bold">
          Place Order
        </button>
      </div>
    </div>
    <!--end::Summary-->
  </form>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "KTDropdownMyCart",
  data() {
    return {
      list: [
        {
          title: "iBlender",
          desc: "Best kichen badge in 2020",
          price: "$ 350",
          quantity: "5",
          img: process.env.BASE_URL + "media/stock-600x400/img-1.jpg"
        },
        {
          title: "SmartCleaner",
          desc: "Smart tool for cooking",
          price: "$ 650",
          quantity: "4",
          img: process.env.BASE_URL + "media/stock-600x400/img-2.jpg"
        },
        {
          title: "CameraX",
          desc: "Professional camera for edge cutting shots",
          price: "$ 150",
          quantity: "3",
          img: process.env.BASE_URL + "media/stock-600x400/img-3.jpg"
        },
        {
          title: "3DPrinted",
          desc: "Manufactoring unique objects",
          price: "$ 1450",
          quantity: "7",
          img: process.env.BASE_URL + "media/stock-600x400/img-4.jpg"
        }
      ]
    };
  },
  computed: {
    ...mapGetters([
      "cartItemCount",
      "cartItems",
      "cartItemTotal",
      "cartItemVatTotal",
      "cartGrandTotal"
    ]),
    backgroundImage() {
      return process.env.BASE_URL + "assets/misc/bg-1.jpg";
    }
  }
};
</script>
<i18n>
{
  "tr": {
    "cartTitle": "Sepetim",
    "cartCount": "{count} Ürün"
  }
}
</i18n>
