import { SignInManager } from "../managers/SignInManager";
export default {
  state: {
    authenticationInfo: null,
    twoFactorToken: null
  },
  mutations: {
    setAuthenticationInfo(state, payload) {
      state.authenticationInfo = payload;
    }
  },
  actions: {
    login(context, credentials) {
      return new Promise((resolve, reject) => {
        window.webApp.signInManager.login(credentials).subscribe(
          result => {
            resolve(result);
          },
          error => {
            reject(error);
          }
        );
      });
    },
    resendValidationCode(context, credentials) {
      return new Promise((resolve, reject) => {
        window.webApp.signInManager.resendValidationCode(credentials).subscribe(
          result => {
            resolve(result);
          },
          error => {
            reject(error);
          }
        );
      });
    },
    validate(context, credentials) {
      return new Promise((resolve, reject) => {
        window.webApp.signInManager.validate(credentials).subscribe(
          result => {
            resolve(result);
          },
          error => {
            reject(error);
          }
        );
      });
    },
    logout() {},
    checkAuthentication(context) {
      return new Promise((resolve, reject) => {
        window.webApp.signInManager.details().subscribe(
          result => {
            context.commit("setAuthenticationInfo", result);
            resolve(result);
          },
          error => {
            context.commit("setAuthenticationInfo", null);
            reject(error);
          }
        );
      });
    },
    setAuthenticationInfo(context, data) {
      context.commit("setAuthenticationInfo", data);
    },
    refreshAuthenticationInfo(context) {
      console.log(context);
      console.log(this._vm);
      const client = new SignInManager(this._vm.$apiContext);
      client.details().subscribe(
        response => {
          context.commit("setAuthenticationInfo", response);
        },
        error => {
          this.$swal({
            text: error.message,
            icon: "error"
          });
          console.error(error);
        }
      );
    },
    hasRole(context, role) {
      if (
        context.state.authenticationInfo == undefined ||
        context.state.authenticationInfo == null
      ) {
        return false;
      }
      if (
        context.state.authenticationInfo.roles == undefined ||
        context.state.authenticationInfo.roles == null ||
        context.state.authenticationInfo.roles.length <= 0
      ) {
        return false;
      }
      console.log(context.state.authenticationInfo.roles.indexOf(role));
      return context.state.authenticationInfo.roles.indexOf(role) > -1;
    }
  },
  getters: {
    hasCurrentUserPhoto(state) {
      if (
        state.authenticationInfo == null ||
        state.authenticationInfo.currentUser == null ||
        state.authenticationInfo.currentUser.profileImage == null ||
        state.authenticationInfo.currentUser.profileImage == ""
      ) {
        return false;
      }
      return true;
    },
    currentUserProfileImage(state) {
      if (
        state.authenticationInfo == null ||
        state.authenticationInfo.currentUser == null ||
        state.authenticationInfo.currentUser.profileImage == null ||
        state.authenticationInfo.currentUser.profileImage == ""
      ) {
        return "";
      }
      return state.authenticationInfo.currentUser.profileImage;
    },
    currentUserEmail(state) {
      return state.authenticationInfo.currentUser.email;
    },
    currentUserPhone(state) {
      return state.authenticationInfo.currentUser.phoneNumber;
    },
    currentUserTitle(state) {
      return state.authenticationInfo.currentUser.title;
    },
    currentUserFullName(state) {
      return (
        state.authenticationInfo.currentUser.firstName +
        " " +
        state.authenticationInfo.currentUser.lastName
      );
    },
    currentUserFirstName(state) {
      return state.authenticationInfo.currentUser.firstName;
    },
    currentUserLastName(state) {
      return state.authenticationInfo.currentUser.lastName;
    },
    currentUserSymbol(state) {
      return (
        state.authenticationInfo.currentUser.firstName.charAt(0).toUpperCase() +
        state.authenticationInfo.currentUser.lastName.charAt(0).toUpperCase()
      );
    },
    currentUserInfo(state) {
      return state.authenticationInfo.currentUser;
    },
    isAuthenticated() {
      return window.webApp.signInManager.hasToken();
    },
    twoFactorToken(state) {
      return state.twoFactorToken;
    },
    currentUserRoles(state) {
      if (
        state.authenticationInfo == undefined ||
        state.authenticationInfo == null
      ) {
        return [];
      }
      if (
        state.authenticationInfo.roles == undefined ||
        state.authenticationInfo.roles == null
      ) {
        return [];
      }
      return state.authenticationInfo.roles;
    },
    isAdmin(state) {
      if (
        state.roles == undefined ||
        state.roles == null ||
        state.roles.length <= 0
      ) {
        return false;
      }
      return state.roles.indexOf("admin") > -1;
    },
    hasNewsAuth(state) {
      if (
        state.roles == undefined ||
        state.roles == null ||
        state.roles.length <= 0
      ) {
        return false;
      }
      return state.roles.indexOf("CHP:NEWS:WRITE") > -1;
    },
    hasPageAuth(state) {
      if (
        state.roles == undefined ||
        state.roles == null ||
        state.roles.length <= 0
      ) {
        return false;
      }
      return state.roles.indexOf("CMS:PAGE:WRITE") > -1;
    },
    hasCategoryAuth(state) {
      if (
        state.roles == undefined ||
        state.roles == null ||
        state.roles.length <= 0
      ) {
        return false;
      }
      return state.roles.indexOf("CMS:CATEGORY:WRITE") > -1;
    },
    hasLiveStreamAuth(state) {
      if (
        state.roles == undefined ||
        state.roles == null ||
        state.roles.length <= 0
      ) {
        return false;
      }
      return state.roles.indexOf("CMS:LIVESTREAM:WRITE") > -1;
    },
    hasPublicationAuth(state) {
      if (
        state.roles == undefined ||
        state.roles == null ||
        state.roles.length <= 0
      ) {
        return false;
      }
      return state.roles.indexOf("CMS:PUBLICATION:WRITE") > -1;
    },
    hasTvAuth(state) {
      if (
        state.roles == undefined ||
        state.roles == null ||
        state.roles.length <= 0
      ) {
        return false;
      }
      return state.roles.indexOf("CMS:TV:WRITE") > -1;
    }
  }
};
