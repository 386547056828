<template>
  <li
    aria-haspopup="true"
    data-menu-toggle="hover"
    class="menu-item menu-item-submenu"
    v-bind:class="{ 'menu-item-open': hasActiveChildren('/vue-bootstrap') }"
  >
    <a href="#" class="menu-link menu-toggle">
      <i class="menu-icon flaticon2-digital-marketing"></i>
      <span class="menu-text">{{ $t(info.label) }}</span>
      <i class="menu-arrow"></i>
    </a>
    <div class="menu-submenu">
      <span class="menu-arrow"></span>
      <ul class="menu-subnav">
        <li aria-haspopup="true" class="menu-item menu-item-parent">
          <span class="menu-link">
            <span class="menu-text">{{ $t(info.label) }}</span>
          </span>
        </li>

        <keep-alive v-for="item in info.subItems" :key="item.id">
          <component v-bind:is="menuItemType(item)" :info="item"></component>
        </keep-alive>
      </ul>
    </div>
  </li>
</template>

<script>
// eslint-disable-next-line vue/no-unused-components
import MenuItem from "@/layout/aside/MenuItem";
// eslint-disable-next-line vue/no-unused-components
import MenuSection from "@/layout/aside/MenuSection";
// eslint-disable-next-line vue/no-unused-components
import SubMenuItem from "@/layout/aside/SubMenuItem";
export default {
  name: "SubMenuItem",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    MenuItem,
    // eslint-disable-next-line vue/no-unused-components
    MenuSection,
    // eslint-disable-next-line vue/no-unused-components
    SubMenuItem
  },
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  methods: {
    menuItemType(item) {
      if (item.type == "MenuItem") {
        if (item.subItems != null && item.subItems.length > 0) {
          return "SubMenuItem";
        }
      }
      return item.type;
    },
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>

<style scoped></style>
