<template>
  <div>
    <div
      class="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <h4 class="d-flex flex-center rounded-top">
        <span class="text-white">{{ $t("title") }}</span>
        <span
          class="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2"
        >
          {{ $t("newNotificationCount", { count: newNotificationCount }) }}
        </span>
      </h4>

      <ul
        class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
        role="tablist"
      >
        <li
          class="nav-item"
          v-for="(category, index) in notificationCategories"
          :key="index"
        >
          <a
            v-on:click="setActiveTab"
            :data-tab="index"
            class="nav-link"
            :class="{ active: index == 0 }"
            data-toggle="tab"
            href="#"
            role="tab"
          >
            {{ $t(category) }}
          </a>
        </li>
      </ul>
    </div>

    <b-tabs class="hide-tabs" v-model="tabIndex">
      <b-tab
        v-for="(notificationCategory, index) in notificationCategories"
        :key="index"
      >
        <perfect-scrollbar
          class="navi navi-hover scroll my-4"
          style="max-height: 40vh; position: relative"
        >
          <template v-for="(item, i) in notifications(notificationCategory)">
            <a href="#" class="navi-item" v-bind:key="i">
              <div class="navi-link">
                <div class="navi-icon mr-2">
                  <i v-bind:class="item.icon"></i>
                </div>
                <div class="navi-text">
                  <div class="font-weight-bold">
                    {{ item.title }}
                  </div>
                  <div class="text-muted">
                    {{ item.description }}
                  </div>
                </div>
              </div>
            </a>
          </template>
        </perfect-scrollbar>
      </b-tab>

      <b-tab>
        <div class="d-flex flex-center text-center text-muted min-h-200px">
          All caught up!<br />No new notifications.
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
import { mapGetters } from "vuex";
export default {
  name: "KTDropdownNotification",
  data() {
    return {
      tabIndex: 0
    };
  },
  methods: {
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    }
  },
  computed: {
    ...mapGetters([
      "notifications",
      "notificationCategories",
      "newNotificationCount"
    ]),
    backgroundImage() {
      return process.env.BASE_URL + "assets/misc/bg-1.jpg";
    }
  }
};
</script>
<i18n>
{
  "tr": {
    "newNotificationCount": "{count} Yeni Bildirim",
    "title": "Bildirimler"
  }
}
</i18n>
