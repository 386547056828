export default [
  {
    path: "/chp",
    redirect: "/cms/dashboard",
    component: () => import(/* webpackChunkName: "Layout" */ "@/layout/Layout"),
    children: [
      {
        path: "/chp/activeRepresentatives",
        name: "chpActiveRepresentativesList",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: true,
            items: [
              {
                title: "chp.activeRepresentatives.list.title",
                route: { name: "chpActiveRepresentativesList" }
              }
            ]
          }
        },
        props: {
          default: {
            formRoute: "chpActiveRepresentativesForm",
            detailRoute: "chpActiveRepresentativesDetail",
            rowClickAction: "edit"
          }
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "ActiveRepresentativesList" */ "@/chp/master/views/title/ActiveRepresentativeList.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "ActiveRepresentativesListActions" */ "@/chp/master/views/title/ActiveRepresentativeListActions.vue"
            )
        }
      },
      {
        path: "/chp/activeRepresentative/:id?",
        name: "chpActiveRepresentativeForm",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: false,
            items: [
              {
                title: "chp.citizen.form.title",
                route: { name: "chpActiveRepresentativeForm" }
              }
            ]
          }
        },
        props: {
          default: route => ({
            formRoute: "chpActiveRepresentativeForm",
            detailRoute: "chpCitizenDetail",
            listRoute: "chpActiveRepresentativesList",
            id: route.params["id"]
          })
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "ActiveRepresentativeForm" */ "@/chp/master/views/title/ActiveRepresentativeForm.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "ActiveRepresentativeFormActions" */ "@/chp/master/views/title/ActiveRepresentativeFormActions.vue"
            )
        }
      },
      {
        path: "/chp/citizen",
        name: "chpCitizenList",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: true,
            items: [
              {
                title: "chp.citizen.list.title",
                route: { name: "chpCitizenList" }
              }
            ]
          }
        },
        props: {
          default: {
            formRoute: "chpCitizenForm",
            detailRoute: "chpCitizenDetail",
            rowClickAction: "edit"
          }
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "CL" */ "@/chp/master/views/citizen/CitizenList.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "CLA" */ "@/chp/master/views/citizen/CitizenListActions.vue"
            )
        }
      },
      {
        path: "/chp/citizen/:id?",
        name: "chpCitizenForm",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: false,
            items: [
              {
                title: "chp.citizen.form.title",
                route: { name: "chpCitizenForm" }
              }
            ]
          }
        },
        props: {
          default: route => ({
            formRoute: "chpCitizenForm",
            detailRoute: "chpCitizenDetail",
            listRoute: "chpCitizenList",
            id: route.params["id"]
          })
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "CCF" */ "@/chp/master/views/citizen/CitizenForm.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "CCFA" */ "@/chp/master/views/citizen/CitizenFormActions.vue"
            )
        }
      },
      {
        path: "/chp/provinces",
        name: "chpProvinceList",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: true,
            items: [
              {
                title: "chp.province.list.title",
                route: { name: "chpProvinceList" }
              }
            ]
          }
        },
        props: {
          default: {
            formRoute: "chpProvinceForm",
            detailRoute: "chpProvinceDetail",
            rowClickAction: "edit"
          }
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "ProvinceList" */ "@/chp/master/views/province/ProvinceList.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "ProvinceListActions" */ "@/chp/master/views/province/ProvinceListActions.vue"
            )
        }
      },
      {
        path: "/chp/province/:id?",
        name: "chpProvinceForm",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: false,
            items: [
              {
                title: "chp.province.form.title",
                route: { name: "chpProvinceForm" }
              }
            ]
          }
        },
        props: {
          default: route => ({
            formRoute: "chpProvinceForm",
            detailRoute: "chpProvinceDetail",
            listRoute: "chpProvinceList",
            id: route.params["id"]
          })
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "ProvinceForm" */ "@/chp/master/views/province/ProvinceForm.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "ProvinceFormActions" */ "@/chp/master/views/province/ProvinceFormActions.vue"
            )
        }
      },
      {
        path: "/chp/districts",
        name: "chpDistrictList",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: true,
            items: [
              {
                title: "chp.district.list.title",
                route: { name: "chpDistrictList" }
              }
            ]
          }
        },
        props: {
          default: {
            formRoute: "chpDistrictForm",
            detailRoute: "chpDistrictDetail",
            rowClickAction: "edit"
          }
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "ProvinceList" */ "@/chp/master/views/district/DistrictList.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "ProvinceListActions" */ "@/chp/master/views/district/DistrictListActions.vue"
            )
        }
      },
      {
        path: "/chp/district/:id?",
        name: "chpDistrictForm",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: false,
            items: [
              {
                title: "chp.district.form.title",
                route: { name: "chpDistrictForm" }
              }
            ]
          }
        },
        props: {
          default: route => ({
            formRoute: "chpDistrictForm",
            detailRoute: "chpDistrictDetail",
            listRoute: "chpDistrictList",
            id: route.params["id"]
          })
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "ProvinceForm" */ "@/chp/master/views/district/DistrictForm.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "ProvinceFormActions" */ "@/chp/master/views/district/DistrictFormActions.vue"
            )
        }
      },
      {
        path: "/chp/neighborhoods",
        name: "chpNeighborhoodList",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: true,
            items: [
              {
                title: "chp.neighborhood.list.title",
                route: { name: "chpNeighborhoodList" }
              }
            ]
          }
        },
        props: {
          default: {
            formRoute: "chpNeighborhoodForm",
            detailRoute: "chpNeighborhoodDetail",
            rowClickAction: "edit"
          }
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "NeighborhoodList" */ "@/chp/master/views/neighborhood/NeighborhoodList.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "NeighborhoodListActions" */ "@/chp/master/views/neighborhood/NeighborhoodListActions.vue"
            )
        }
      },
      {
        path: "/chp/neighborhood/:id?",
        name: "chpNeighborhoodForm",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: false,
            items: [
              {
                title: "chp.neighborhood.form.title",
                route: { name: "chpNeighborhoodForm" }
              }
            ]
          }
        },
        props: {
          default: route => ({
            formRoute: "chpNeighborhoodForm",
            detailRoute: "chpNeighborhoodDetail",
            listRoute: "chpNeighborhoodList",
            id: route.params["id"]
          })
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "NeighborhoodForm" */ "@/chp/master/views/neighborhood/NeighborhoodForm.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "NeighborhoodFormActions" */ "@/chp/master/views/neighborhood/NeighborhoodFormActions.vue"
            )
        }
      },
      {
        path: "/chp/responsibles",
        name: "chpResponsibleList",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: true,
            items: [
              {
                title: "chp.responsible.list.title",
                route: { name: "chpResponsibleList" }
              }
            ]
          }
        },
        props: {
          default: {
            formRoute: "chpResponsibleForm",
            detailRoute: "chpResponsibleDetail",
            rowClickAction: "edit"
          }
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "ResponsibleList" */ "@/chp/master/views/responsible/ResponsibleList.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "ResponsibleListActions" */ "@/chp/master/views/responsible/ResponsibleListActions.vue"
            )
        }
      },
      {
        path: "/chp/responsible/:id?",
        name: "chpResponsibleForm",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: false,
            items: [
              {
                title: "chp.responsible.form.title",
                route: { name: "chpResponsibleForm" }
              }
            ]
          }
        },
        props: {
          default: route => ({
            formRoute: "chpResponsibleForm",
            detailRoute: "chpResponsibleDetail",
            listRoute: "chpResponsibleList",
            id: route.params["id"]
          })
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "ResponsibleForm" */ "@/chp/master/views/responsible/ResponsibleForm.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "ResponsibleFormActions" */ "@/chp/master/views/responsible/ResponsibleFormActions.vue"
            )
        }
      },
      {
        path: "/chp/titleTypes",
        name: "chpTitleTypeList",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: true,
            items: [
              {
                title: "chp.titleType.list.title",
                route: { name: "chpTitleTypeList" }
              }
            ]
          }
        },
        props: {
          default: {
            formRoute: "chpTitleTypeForm",
            detailRoute: "chpTitleTypeDetail",
            rowClickAction: "edit"
          }
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "TitleTypeList" */ "@/chp/master/views/titleType/TitleTypeList.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "TitleTypeListActions" */ "@/chp/master/views/titleType/TitleTypeListActions.vue"
            )
        }
      },
      {
        path: "/chp/titleType/:id?",
        name: "chpTitleTypeForm",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: false,
            items: [
              {
                title: "chp.titleType.form.title",
                route: { name: "chpTitleTypeForm" }
              }
            ]
          }
        },
        props: {
          default: route => ({
            formRoute: "chpTitleTypeForm",
            detailRoute: "chpTitleTypeDetail",
            listRoute: "chpTitleTypeList",
            id: route.params["id"]
          })
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "TitleTypeForm" */ "@/chp/master/views/titleType/TitleTypeForm.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "TitleTypeFormActions" */ "@/chp/master/views/titleType/TitleTypeFormActions.vue"
            )
        }
      },
      {
        path: "/chp/titles",
        name: "chpTitleList",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: true,
            items: [
              {
                title: "chp.title.list.title",
                route: { name: "chpTitleList" }
              }
            ]
          }
        },
        props: {
          default: {
            formRoute: "chpTitleForm",
            detailRoute: "chpTitleDetail",
            rowClickAction: "edit"
          }
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "TitleList" */ "@/chp/master/views/title/TitleList.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "TitleListActions" */ "@/chp/master/views/title/TitleListActions.vue"
            )
        }
      },
      {
        path: "/chp/title/:id?",
        name: "chpTitleForm",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: false,
            items: [
              {
                title: "chp.title.form.title",
                route: { name: "chpTitleForm" }
              }
            ]
          }
        },
        props: {
          default: route => ({
            formRoute: "chpTitleForm",
            detailRoute: "chpTitleDetail",
            listRoute: "chpTitleList",
            id: route.params["id"]
          })
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "TitleForm" */ "@/chp/master/views/title/TitleForm.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "TitleTypeFormActions" */ "@/chp/master/views/title/TitleFormActions.vue"
            )
        }
      },
      {
        path: "/chp/commissions",
        name: "chpCommissionList",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: true,
            items: [
              {
                title: "chp.commission.list.title",
                route: { name: "chpCommissionList" }
              }
            ]
          }
        },
        props: {
          default: {
            formRoute: "chpCommissionForm",
            detailRoute: "chpCommissionDetail",
            rowClickAction: "edit"
          }
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "CommissionList" */ "@/chp/master/views/commission/CommissionList.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "CommissionListActions" */ "@/chp/master/views/commission/CommissionListActions.vue"
            )
        }
      },
      {
        path: "/chp/commission/:id?",
        name: "chpCommissionForm",
        meta: {
          authorize: true,
          breadcrumb: {
            isRoot: false,
            items: [
              {
                title: "chp.commission.form.title",
                route: { name: "chpCommissionForm" }
              }
            ]
          }
        },
        props: {
          default: route => ({
            formRoute: "chpCommissionForm",
            detailRoute: "chpCommissionDetail",
            listRoute: "chpCommissionList",
            id: route.params["id"]
          })
        },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "CommissionForm" */ "@/chp/master/views/commission/CommissionForm.vue"
            ),
          actions: () =>
            import(
              /* webpackChunkName: "CommissionTypeFormActions" */ "@/chp/master/views/commission/CommissionFormActions.vue"
            )
        }
      }
    ]
  }
];
