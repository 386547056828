import { ApiContext } from "@appkit-vue/data";

export default new ApiContext({
  baseURL: process.env.VUE_APP_API_BASEURL,
  origin: process.env.VUE_APP_API_ORIGIN,
  tokenKey: process.env.VUE_APP_TOKEN_KEY,
  onRequest() {
    var currentSite = window.webApp.store.getters.currentSite;
    if (currentSite != null) {
      return { "X-CMS-SITE": currentSite.id };
    }
    return {};
    //return window.webApp.store.getters.ge;
  },
  beforeSend() {
    var currentSite = window.webApp.store.getters.currentSite;
    if (currentSite != null) {
      return { headers: { "X-CMS-SITE": currentSite.id } };
    }
    return {};
  }
});
