import { default as WebAppRoot } from "./WebAppRoot.vue";
import { default as Layout } from "@/layout/Layout";
import { default as Loader } from "@/components/Loader";
import { default as KTAside } from "@/layout/aside/Aside";
import { default as KTMenu } from "@/layout/aside/Menu";
import { default as MenuItem } from "@/layout/aside/MenuItem";
import { default as MenuSection } from "@/layout/aside/MenuSection";
import { default as SubMenuItem } from "@/layout/aside/SubMenuItem";
import { default as KTBrand } from "@/layout/brand/Brand";
import { default as KTDropdownLanguage } from "@/layout/extras/dropdown/DropdownLanguage";
import { default as KTDropdownMyCart } from "@/layout/extras/dropdown/DropdownMyCart";
import { default as KTDropdownNotification } from "@/layout/extras/dropdown/DropdownNotification";
import { default as KTDropdownQuickAction } from "@/layout/extras/dropdown/DropdownQuickAction";
import { default as KTSearchDefault } from "@/layout/extras/dropdown/SearchDefault";
import { default as KTSearchResult } from "@/layout/extras/dropdown/SearchResult";
import { default as KTQuickPanel } from "@/layout/extras/offcanvas/QuickPanel";
import { default as KTQuickUser } from "@/layout/extras/offcanvas/QuickUser";
import { default as KTScrollTop } from "@/layout/extras/ScrollTop";
import { default as KTStickyToolbar } from "@/layout/extras/StickyToolbar";
import { default as Error } from "@/error/Error";
import { default as KTFooter } from "@/layout/footer/Footer";
import { default as KTHeader } from "@/layout/header/Header";
import { default as KTHeaderMobile } from "@/layout/header/HeaderMobile";
import { default as KTTopbar } from "@/layout/header/Topbar";
import { default as KTSubHeader } from "@/layout/subheader/Subheader";

import VueI18n from "vue-i18n";
import AppKitUI from "@appkit-vue/ui";
import "popper.js";
import "tooltip.js";
import VuePerfectScrollbar from "vue2-perfect-scrollbar";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
export {
  Layout,
  Loader,
  KTAside,
  KTMenu,
  MenuItem,
  MenuSection,
  SubMenuItem,
  KTBrand,
  KTDropdownLanguage,
  KTDropdownMyCart,
  KTDropdownNotification,
  KTDropdownQuickAction,
  KTSearchDefault,
  KTSearchResult,
  KTQuickPanel,
  KTQuickUser,
  KTScrollTop,
  KTStickyToolbar,
  Error,
  KTFooter,
  KTHeader,
  KTHeaderMobile,
  KTTopbar,
  KTSubHeader
};
import { InlineSvgPlugin } from "vue-inline-svg";
import WebAppRoutes from "./router";
import VueRouter from "vue-router";
import guard from "./router/guard";
import ConfigStoreModule from "./store/config.module";
import AuthStoreModule from "./store/auth.module";
import HtmlClassStoreModule from "./store/htmlclass.module";
import BreadCrumbsStoreModule from "./store/breadcrumbs.module";
import NotificationsStoreModule from "./store/notification.module";
import SearchStoreModule from "./store/search.module";
import CartStoreModule from "./store/cart.module";
import "@/plugins/metronic";
import UUID from "vue-uuid";

import { SignInManager } from "@/managers/SignInManager";
const Components = {
  WebAppRoot,
  Layout,
  Loader,
  KTAside,
  KTMenu,
  MenuItem,
  MenuSection,
  SubMenuItem,
  KTBrand,
  KTDropdownLanguage,
  KTDropdownMyCart,
  KTDropdownNotification,
  KTDropdownQuickAction,
  KTSearchDefault,
  KTSearchResult,
  KTQuickPanel,
  KTQuickUser,
  KTScrollTop,
  KTStickyToolbar,
  Error,
  KTFooter,
  KTHeader,
  KTHeaderMobile,
  KTTopbar,
  KTSubHeader
};

const WebAppStoreModules = {
  ConfigStoreModule,
  AuthStoreModule,
  HtmlClassStoreModule,
  BreadCrumbsStoreModule,
  NotificationsStoreModule,
  SearchStoreModule,
  CartStoreModule
};

export { WebAppRoot, VueI18n, WebAppRoutes, VueRouter, WebAppStoreModules };

export default {
  install(Vue, options) {
    console.log("appkit-web-app installing");
    options.router.beforeEach((to, from, next) => {
      guard.beforeRoute(to, from, next, options.routeHandlers);
    });
    options.store.dispatch("setConfig", options.config);
    Vue.WebAppOptions = options;
    const webApp = {
      store: options.store,
      identityApi: options.identityApi,
      signInManager: new SignInManager(options.identityApi)
    };
    window.webApp = webApp;
    Vue.prototype.$webApp = webApp;

    Vue.use(VueRouter);
    Vue.use(VueI18n);
    Vue.use(AppKitUI);
    Vue.use(InlineSvgPlugin);
    Vue.use(VuePerfectScrollbar);
    Vue.use(UUID);
    Object.keys(Components).forEach(name => {
      Vue.component(name, Components[name]);
    });
    console.log("appkit-web-app installed");
  }
};
