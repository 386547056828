var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{attrs:{"to":{ name: _vm.info.routeName, params: Object.assign({}, _vm.info.routeParams) }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
      isActive && 'menu-item-active',
      isExactActive && 'menu-item-active'
    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[(_vm.info.icon !== null)?_c('i',{staticClass:"menu-icon",class:_vm.info.icon}):_vm._e(),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t(_vm.info.label)))])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }