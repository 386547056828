<template>
  <router-view />
</template>

<script>
import { locale } from "devextreme/localization";

export default {
  name: "WebAppRoot",
  created() {
    locale("tr");
  }
};
</script>

<style lang="scss">
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~animate.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";
@import "assets/fonts/gotham/gotham.css";
// Main demo style scss
@import "assets/sass/style.vue";
</style>
