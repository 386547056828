export default {
  state: {
    items: [
      /*{
                title: "iBlender",
                desc: "Best kichen badge in 2020",
                price: 350,
                quantity: 5,
                vatRate:18
                //img: process.env.BASE_URL + "media/stock-600x400/img-1.jpg",
            }*/
    ]
  },
  mutations: {},
  actions: {},
  getters: {
    cartItems(state) {
      return state.items;
    },
    cartItemCount(state) {
      return state.items.length;
    },
    cartItemTotal(state) {
      let total = 0;
      for (const item in state.items) {
        total += item.price * item.quantity;
      }
      return total;
    },
    cartItemVatTotal(state) {
      let total = 0;
      for (const item in state.items) {
        total += item.price * item.quantity * (item.vatRate / 100);
      }
      return total;
    },
    cartGrandTotal(state) {
      let total = 0;
      for (const item in state.items) {
        total +=
          item.price * item.quantity +
          item.price * item.quantity * (item.vatRate / 100);
      }
      return total;
    }
  }
};
