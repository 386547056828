export default {
  state: {
    notifications: [
      /*
            {
                id:"001",
                title:"Notification Title",
                description:"Notification Description",
                icon:"fas fa-users",
                isNew:false,
                route:"login",
                category:"General"
            },
            */
    ]
  },
  mutations: {},
  actions: {},
  getters: {
    notifications: state => category => {
      if (category === undefined || category === null) {
        return state.notifications;
      }
      return state.notifications.filter(x => x.category === category);
    },
    notificationCategories(state) {
      return new Set(state.notifications.map(x => x.category));
    },
    newNotificationCount(state) {
      return state.notifications.filter(x => x.isNew).length;
    }
  }
};
