<template>
  <ul class="menu-nav">
    <keep-alive v-for="item in asideMenuItems" :key="item.id">
      <component
        v-if="isMenuShow(item)"
        v-bind:is="menuItemType(item)"
        :info="item"
      ></component>
    </keep-alive>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";
// eslint-disable-next-line vue/no-unused-components
import MenuItem from "@/layout/aside/MenuItem";
// eslint-disable-next-line vue/no-unused-components
import MenuSection from "@/layout/aside/MenuSection";
// eslint-disable-next-line vue/no-unused-components
import SubMenuItem from "@/layout/aside/SubMenuItem";
export default {
  name: "KTMenu",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    MenuItem,
    // eslint-disable-next-line vue/no-unused-components
    MenuSection,
    // eslint-disable-next-line vue/no-unused-components
    SubMenuItem
  },
  computed: {
    ...mapGetters(["asideMenuItems", "currentUserRoles"])
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    menuItemType(item) {
      if (item.type === "MenuItem") {
        if (item.subItems !== null && item.subItems.length > 0) {
          return "SubMenuItem";
        }
      }
      return item.type;
    },
    isMenuShow(item) {
      if (
        item.roles == undefined ||
        item.roles == null ||
        item.roles.length <= 0
      ) {
        return true;
      }
      var result = false;
      for (let i = 0; i < item.roles.length; i++) {
        const role = item.roles[i];
        if (this.currentUserRoles.indexOf(role) > -1) {
          return true;
        }
      }
      return result;
    }
  }
};
</script>
