<template>
  <li class="menu-section">
    <h4 class="menu-text">{{ $t(info.label) }}</h4>
    <i class="menu-icon flaticon-more-v2"></i>
  </li>
</template>

<script>
export default {
  name: "MenuSection",
  props: {
    info: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped></style>
